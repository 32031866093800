// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Textarea from "../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconDelete from "../../../../styleguide/icons/IconDelete.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as UserColocationRfpProjectWizardTooltip from "../_components/UserColocationRfpProjectWizardTooltip/UserColocationRfpProjectWizardTooltip.res.js";
import * as CreateColocationRfpProjectConfigurationScss from "./CreateColocationRfpProjectConfiguration.scss";
import * as CreateColocationRfpProjectConfiguration_Data_InternetConnectivity from "./CreateColocationRfpProjectConfiguration_Data_InternetConnectivity.res.js";

var css = CreateColocationRfpProjectConfigurationScss;

function CreateColocationRfpProjectConfiguration_InternetConnectivity(props) {
  var toggleVisibility = props.toggleVisibility;
  var sectionVisibility = props.sectionVisibility;
  var options = props.options;
  var updateConfiguration = props.updateConfiguration;
  var configuration = props.configuration;
  var mainIndex = props.mainIndex;
  var generateId = props.generateId;
  var updateInternetConnectivities = function (configuration, internetConnectivity, mainIndex, subIndex) {
    var internetConnectivities = Belt_Array.mapWithIndex(configuration.internetConnectivities, (function (idx, oldinternetConnectivity) {
            if (idx === subIndex) {
              return internetConnectivity;
            } else {
              return oldinternetConnectivity;
            }
          }));
    updateConfiguration({
          key: configuration.key,
          startDate: configuration.startDate,
          contractLength: configuration.contractLength,
          rackTypesAndPowerDensities: configuration.rackTypesAndPowerDensities,
          internetConnectivities: internetConnectivities,
          crossConnects: configuration.crossConnects,
          cloudDirectConnects: configuration.cloudDirectConnects,
          certificationRequirements: configuration.certificationRequirements,
          datacenterFeatures: configuration.datacenterFeatures,
          additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
        }, mainIndex);
  };
  var tmp;
  tmp = sectionVisibility === "Shown" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            Belt_Array.mapWithIndex(configuration.internetConnectivities, (function (subIndex, internetConnectivity) {
                    return JsxRuntime.jsxs("div", {
                                children: [
                                  subIndex > 0 ? JsxRuntime.jsx(Control.make, {
                                          className: css.deleteSubSection,
                                          onClick: (function (param) {
                                              var internetConnectivities = Belt_Array.keepWithIndex(configuration.internetConnectivities, (function (_value, index) {
                                                      return index !== subIndex;
                                                    }));
                                              updateConfiguration({
                                                    key: configuration.key,
                                                    startDate: configuration.startDate,
                                                    contractLength: configuration.contractLength,
                                                    rackTypesAndPowerDensities: configuration.rackTypesAndPowerDensities,
                                                    internetConnectivities: internetConnectivities,
                                                    crossConnects: configuration.crossConnects,
                                                    cloudDirectConnects: configuration.cloudDirectConnects,
                                                    certificationRequirements: configuration.certificationRequirements,
                                                    datacenterFeatures: configuration.datacenterFeatures,
                                                    additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
                                                  }, mainIndex);
                                            }),
                                          children: JsxRuntime.jsx(IconDelete.make, {
                                                size: "SM",
                                                color: "LightGray"
                                              })
                                        }) : null,
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                        field: "Interconnect",
                                                        children: JsxRuntime.jsx(Label.make, {
                                                              forId: generateId("interconnect", mainIndex, "internet", subIndex, undefined),
                                                              children: "Access Type"
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs(Select.make, {
                                                        id: generateId("interconnect", mainIndex, "internet", subIndex, undefined),
                                                        value: Belt_Option.getWithDefault(internetConnectivity.interconnect, ""),
                                                        wrapperClassName: css.internetConnectivityInterconnect,
                                                        iconSize: "XXS",
                                                        iconColor: "GrayText",
                                                        onChange: (function ($$event) {
                                                            var value = $$event.target.value;
                                                            updateInternetConnectivities(configuration, {
                                                                  interconnect: value,
                                                                  bandwidth: internetConnectivity.bandwidth,
                                                                  provider: internetConnectivity.provider,
                                                                  additionalOptions: internetConnectivity.additionalOptions,
                                                                  specialNotesAndRequirements: internetConnectivity.specialNotesAndRequirements
                                                                }, mainIndex, subIndex);
                                                          }),
                                                        children: [
                                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                                value: "",
                                                                children: "Select"
                                                              }),
                                                          Belt_Array.map(options.interconnect, (function (value) {
                                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                                              value: value,
                                                                              children: value
                                                                            }, value);
                                                                }))
                                                        ]
                                                      })
                                                ],
                                                className: css.field
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                        field: "Bandwidth",
                                                        children: JsxRuntime.jsx(Label.make, {
                                                              forId: generateId("bandwidth", mainIndex, "internet", subIndex, undefined),
                                                              children: "Bandwidth"
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs(Select.make, {
                                                        id: generateId("bandwidth", mainIndex, "internet", subIndex, undefined),
                                                        value: Belt_Option.getWithDefault(internetConnectivity.bandwidth, ""),
                                                        wrapperClassName: css.internetConnectivityBandwidth,
                                                        iconSize: "XXS",
                                                        iconColor: "GrayText",
                                                        onChange: (function ($$event) {
                                                            var value = $$event.target.value;
                                                            updateInternetConnectivities(configuration, {
                                                                  interconnect: internetConnectivity.interconnect,
                                                                  bandwidth: value,
                                                                  provider: internetConnectivity.provider,
                                                                  additionalOptions: internetConnectivity.additionalOptions,
                                                                  specialNotesAndRequirements: internetConnectivity.specialNotesAndRequirements
                                                                }, mainIndex, subIndex);
                                                          }),
                                                        children: [
                                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                                value: "",
                                                                children: "Select"
                                                              }),
                                                          Belt_Array.map(options.bandwidths, (function (value) {
                                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                                              value: value,
                                                                              children: value
                                                                            }, value);
                                                                }))
                                                        ]
                                                      })
                                                ],
                                                className: css.field
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                        field: "Preferred Provider",
                                                        children: JsxRuntime.jsx(Label.make, {
                                                              forId: generateId("provider", mainIndex, "internet", subIndex, undefined),
                                                              children: "Preferred Provider"
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs(Select.make, {
                                                        id: generateId("provider", mainIndex, "internet", subIndex, undefined),
                                                        value: Belt_Option.getWithDefault(internetConnectivity.provider, ""),
                                                        wrapperClassName: css.internetConnectivityPreferredProvider,
                                                        iconSize: "XXS",
                                                        iconColor: "GrayText",
                                                        onChange: (function ($$event) {
                                                            var value = $$event.target.value;
                                                            updateInternetConnectivities(configuration, {
                                                                  interconnect: internetConnectivity.interconnect,
                                                                  bandwidth: internetConnectivity.bandwidth,
                                                                  provider: value,
                                                                  additionalOptions: internetConnectivity.additionalOptions,
                                                                  specialNotesAndRequirements: internetConnectivity.specialNotesAndRequirements
                                                                }, mainIndex, subIndex);
                                                          }),
                                                        children: [
                                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                                value: "",
                                                                children: "Select"
                                                              }),
                                                          Belt_Array.map(options.providers, (function (value) {
                                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                                              value: value,
                                                                              children: value
                                                                            }, value);
                                                                }))
                                                        ]
                                                      })
                                                ],
                                                className: css.field
                                              })
                                        ],
                                        className: css.subSection
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: Belt_Array.map(options.additionalInternetConnectivity, (function (additionalOption) {
                                                return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                            id: generateId("additional-option-" + additionalOption, mainIndex, "internet", subIndex, undefined),
                                                            size: "SM",
                                                            checked: (function (__x) {
                                                                  return Js_array.includes(additionalOption, __x);
                                                                })(internetConnectivity.additionalOptions),
                                                            containerClassName: css.option,
                                                            onChange: (function ($$event) {
                                                                var checked = $$event.target.checked;
                                                                var additionalOptions = checked ? Belt_Array.concat(internetConnectivity.additionalOptions, [additionalOption]) : Belt_Array.keep(internetConnectivity.additionalOptions, (function (value) {
                                                                          return value !== additionalOption;
                                                                        }));
                                                                updateInternetConnectivities(configuration, {
                                                                      interconnect: internetConnectivity.interconnect,
                                                                      bandwidth: internetConnectivity.bandwidth,
                                                                      provider: internetConnectivity.provider,
                                                                      additionalOptions: additionalOptions,
                                                                      specialNotesAndRequirements: internetConnectivity.specialNotesAndRequirements
                                                                    }, mainIndex, subIndex);
                                                              }),
                                                            children: JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                                  field: additionalOption,
                                                                  children: additionalOption
                                                                })
                                                          }, additionalOption);
                                              })),
                                        className: css.subSection
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx(Label.make, {
                                                      forId: generateId("special-notes-and-requirements", mainIndex, "internet", subIndex, undefined),
                                                      children: "Special Notes and Requirements"
                                                    }),
                                                JsxRuntime.jsx(Textarea.make, {
                                                      id: generateId("special-notes-and-requirements", mainIndex, "internet", subIndex, undefined),
                                                      value: Belt_Option.getWithDefault(internetConnectivity.specialNotesAndRequirements, ""),
                                                      className: css.internetConnectivitySpecialNotes,
                                                      onChange: (function ($$event) {
                                                          var value = $$event.target.value;
                                                          updateInternetConnectivities(configuration, {
                                                                interconnect: internetConnectivity.interconnect,
                                                                bandwidth: internetConnectivity.bandwidth,
                                                                provider: internetConnectivity.provider,
                                                                additionalOptions: internetConnectivity.additionalOptions,
                                                                specialNotesAndRequirements: value
                                                              }, mainIndex, subIndex);
                                                        })
                                                    })
                                              ],
                                              className: Cx.cx([
                                                    css.field,
                                                    css.fullWidth
                                                  ])
                                            }),
                                        className: css.subSection
                                      })
                                ],
                                className: css.repeatedSection
                              }, String(subIndex));
                  })),
            JsxRuntime.jsx(Button.make, {
                  size: "MD",
                  color: "Gray",
                  buttonClassName: css.addRepeatedSection,
                  onClick: (function (param) {
                      updateConfiguration({
                            key: configuration.key,
                            startDate: configuration.startDate,
                            contractLength: configuration.contractLength,
                            rackTypesAndPowerDensities: configuration.rackTypesAndPowerDensities,
                            internetConnectivities: Belt_Array.concat(configuration.internetConnectivities, [CreateColocationRfpProjectConfiguration_Data_InternetConnectivity.Input.empty]),
                            crossConnects: configuration.crossConnects,
                            cloudDirectConnects: configuration.cloudDirectConnects,
                            certificationRequirements: configuration.certificationRequirements,
                            datacenterFeatures: configuration.datacenterFeatures,
                            additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
                          }, mainIndex);
                    }),
                  children: "+ Add Another"
                })
          ]
        }) : null;
  return JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
              title: "Bandwidth Requirements",
              visibility: sectionVisibility,
              toggleVisibility: (function () {
                  toggleVisibility(mainIndex, "InternetConnectivity");
                }),
              children: tmp
            });
}

var make = CreateColocationRfpProjectConfiguration_InternetConnectivity;

export {
  css ,
  make ,
}
/* css Not a pure module */
