// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as UserColocationRfpProjectWizardTooltip from "../_components/UserColocationRfpProjectWizardTooltip/UserColocationRfpProjectWizardTooltip.res.js";
import * as CreateColocationRfpProjectConfigurationScss from "./CreateColocationRfpProjectConfiguration.scss";

var css = CreateColocationRfpProjectConfigurationScss;

function CreateColocationRfpProjectConfiguration_CertificationRequirements(props) {
  var mobile = props.mobile;
  var toggleVisibility = props.toggleVisibility;
  var sectionVisibility = props.sectionVisibility;
  var options = props.options;
  var updateConfiguration = props.updateConfiguration;
  var configuration = props.configuration;
  var mainIndex = props.mainIndex;
  var generateId = props.generateId;
  var itemsPerRow = mobile ? 1 : 3;
  var totalOptions = options.certificationRequirements.length;
  var certificationsNumOfRows = mobile ? totalOptions - 1 | 0 : Caml_int32.div(totalOptions, itemsPerRow);
  var tmp;
  tmp = sectionVisibility === "Shown" ? Belt_Array.mapWithIndex(Belt_Array.range(0, certificationsNumOfRows), (function (index, param) {
            return JsxRuntime.jsx("div", {
                        children: Belt_Array.map(Belt_Array.slice(options.certificationRequirements, Math.imul(index, itemsPerRow), itemsPerRow), (function (certificationRequirement) {
                                return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                            id: generateId("certification-requirement-" + certificationRequirement, mainIndex, undefined, undefined, undefined),
                                            size: "SM",
                                            checked: (function (__x) {
                                                  return Js_array.includes(certificationRequirement, __x);
                                                })(configuration.certificationRequirements),
                                            containerClassName: css.option,
                                            onChange: (function ($$event) {
                                                var checked = $$event.target.checked;
                                                var certificationRequirements = checked ? Belt_Array.concat(configuration.certificationRequirements, [certificationRequirement]) : Belt_Array.keep(configuration.certificationRequirements, (function (value) {
                                                          return value !== certificationRequirement;
                                                        }));
                                                updateConfiguration({
                                                      key: configuration.key,
                                                      startDate: configuration.startDate,
                                                      contractLength: configuration.contractLength,
                                                      rackTypesAndPowerDensities: configuration.rackTypesAndPowerDensities,
                                                      internetConnectivities: configuration.internetConnectivities,
                                                      crossConnects: configuration.crossConnects,
                                                      cloudDirectConnects: configuration.cloudDirectConnects,
                                                      certificationRequirements: certificationRequirements,
                                                      datacenterFeatures: configuration.datacenterFeatures,
                                                      additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
                                                    }, mainIndex);
                                              }),
                                            children: JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                  field: certificationRequirement,
                                                  children: certificationRequirement
                                                })
                                          }, certificationRequirement);
                              })),
                        className: css.certificationRow
                      }, "certifications-row" + String(index));
          })) : null;
  return JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
              title: "Certification Requirements",
              visibility: sectionVisibility,
              toggleVisibility: (function () {
                  toggleVisibility(mainIndex, "CertificationRequirements");
                }),
              children: tmp
            });
}

var make = CreateColocationRfpProjectConfiguration_CertificationRequirements;

export {
  css ,
  make ,
}
/* css Not a pure module */
