// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ColocationRfpProject from "../../../../models/ColocationRfpProject.res.js";
import * as ColocationConfiguration from "../../../../models/ColocationConfiguration.res.js";
import * as CreateColocationRfpProjectConfiguration from "./CreateColocationRfpProjectConfiguration.res.js";
import * as CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration from "./CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.res.js";

function CreateColocationRfpProjectConfiguration__JsBridge$default(props) {
  var status = props.status;
  var railsContext = props.railsContext;
  var projectId = props.projectId;
  var options = props.options;
  var colocationConfigurations = props.colocationConfigurations;
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var projectId$1 = React.useMemo((function () {
          return Belt_Option.map((projectId == null) ? undefined : Caml_option.some(projectId), (function (prim) {
                        return prim;
                      }));
        }), [projectId]);
  var colocationConfigurations$1 = React.useMemo((function () {
          return Belt_Array.map(Belt_Array.map(colocationConfigurations, ColocationConfiguration.fromJs), CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Input.fromColocationConfiguration);
        }), [colocationConfigurations]);
  var options$1 = React.useMemo((function () {
          return ColocationRfpProject.ConfigurationOptions.fromJson(options);
        }), [options]);
  var projectStatus = React.useMemo((function () {
          return Project.Status.fromString(status);
        }), [status]);
  return JsxRuntime.jsx(CreateColocationRfpProjectConfiguration.make, {
              colocationConfigurations: colocationConfigurations$1,
              options: options$1,
              projectId: projectId$1,
              mobile: railsContext$1.mobile,
              userLoginStatus: railsContext$1.userLoginStatus,
              setUserData: props.reduxfunction.setUserData,
              projectStatus: projectStatus
            });
}

var $$default = CreateColocationRfpProjectConfiguration__JsBridge$default;

export {
  $$default as default,
}
/* react Not a pure module */
