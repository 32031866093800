// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Textarea from "../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as CreateColocationRfpProjectConfigurationScss from "./CreateColocationRfpProjectConfiguration.scss";

var css = CreateColocationRfpProjectConfigurationScss;

function CreateColocationRfpProjectConfiguration_AdditionalNotes(props) {
  var toggleVisibility = props.toggleVisibility;
  var sectionVisibility = props.sectionVisibility;
  var updateConfiguration = props.updateConfiguration;
  var configuration = props.configuration;
  var mainIndex = props.mainIndex;
  var tmp;
  tmp = sectionVisibility === "Shown" ? JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(Textarea.make, {
                id: props.generateId("additional-notes-and-requirements", mainIndex, undefined, undefined, undefined),
                value: Belt_Option.getWithDefault(configuration.additionalNotesOrRequirements, ""),
                onChange: (function ($$event) {
                    var value = $$event.target.value;
                    updateConfiguration({
                          key: configuration.key,
                          startDate: configuration.startDate,
                          contractLength: configuration.contractLength,
                          rackTypesAndPowerDensities: configuration.rackTypesAndPowerDensities,
                          internetConnectivities: configuration.internetConnectivities,
                          crossConnects: configuration.crossConnects,
                          cloudDirectConnects: configuration.cloudDirectConnects,
                          certificationRequirements: configuration.certificationRequirements,
                          datacenterFeatures: configuration.datacenterFeatures,
                          additionalNotesOrRequirements: value
                        }, mainIndex);
                  })
              }),
          className: Cx.cx([
                css.field,
                css.fullWidth
              ])
        }) : null;
  return JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
              title: "Add Additional Notes",
              visibility: sectionVisibility,
              toggleVisibility: (function () {
                  toggleVisibility(mainIndex, "AdditionalNotes");
                }),
              children: tmp
            });
}

var itemsPerRow = 3;

var make = CreateColocationRfpProjectConfiguration_AdditionalNotes;

export {
  css ,
  itemsPerRow ,
  make ,
}
/* css Not a pure module */
