// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Textarea from "../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as TextField from "../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconDelete from "../../../../styleguide/icons/IconDelete.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as UserColocationRfpProjectWizardTooltip from "../_components/UserColocationRfpProjectWizardTooltip/UserColocationRfpProjectWizardTooltip.res.js";
import * as CreateColocationRfpProjectConfigurationScss from "./CreateColocationRfpProjectConfiguration.scss";
import * as CreateColocationRfpProjectConfiguration_Data_CrossConnect from "./CreateColocationRfpProjectConfiguration_Data_CrossConnect.res.js";

var css = CreateColocationRfpProjectConfigurationScss;

function CreateColocationRfpProjectConfiguration_CrossConnect(props) {
  var toggleVisibility = props.toggleVisibility;
  var sectionVisibility = props.sectionVisibility;
  var options = props.options;
  var updateConfiguration = props.updateConfiguration;
  var configuration = props.configuration;
  var mainValidationResults = props.mainValidationResults;
  var mainIndex = props.mainIndex;
  var generateId = props.generateId;
  var updateCrossConnects = function (configuration, crossConnect, mainIndex, subIndex) {
    var crossConnects = Belt_Array.mapWithIndex(configuration.crossConnects, (function (idx, oldCrossConnect) {
            if (idx === subIndex) {
              return crossConnect;
            } else {
              return oldCrossConnect;
            }
          }));
    updateConfiguration({
          key: configuration.key,
          startDate: configuration.startDate,
          contractLength: configuration.contractLength,
          rackTypesAndPowerDensities: configuration.rackTypesAndPowerDensities,
          internetConnectivities: configuration.internetConnectivities,
          crossConnects: crossConnects,
          cloudDirectConnects: configuration.cloudDirectConnects,
          certificationRequirements: configuration.certificationRequirements,
          datacenterFeatures: configuration.datacenterFeatures,
          additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
        }, mainIndex);
  };
  var tmp;
  tmp = sectionVisibility === "Shown" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            Belt_Array.mapWithIndex(configuration.crossConnects, (function (subIndex, crossConnect) {
                    var subValidationResults = mainValidationResults !== undefined ? Belt_Array.get(mainValidationResults.crossConnects, subIndex) : undefined;
                    var value = crossConnect.quantity;
                    return JsxRuntime.jsxs("div", {
                                children: [
                                  subIndex > 0 ? JsxRuntime.jsx(Control.make, {
                                          className: css.deleteSubSection,
                                          onClick: (function (param) {
                                              var crossConnects = Belt_Array.keepWithIndex(configuration.crossConnects, (function (_value, index) {
                                                      return index !== subIndex;
                                                    }));
                                              updateConfiguration({
                                                    key: configuration.key,
                                                    startDate: configuration.startDate,
                                                    contractLength: configuration.contractLength,
                                                    rackTypesAndPowerDensities: configuration.rackTypesAndPowerDensities,
                                                    internetConnectivities: configuration.internetConnectivities,
                                                    crossConnects: crossConnects,
                                                    cloudDirectConnects: configuration.cloudDirectConnects,
                                                    certificationRequirements: configuration.certificationRequirements,
                                                    datacenterFeatures: configuration.datacenterFeatures,
                                                    additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
                                                  }, mainIndex);
                                            }),
                                          children: JsxRuntime.jsx(IconDelete.make, {
                                                size: "SM",
                                                color: "LightGray"
                                              })
                                        }) : null,
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Label.make, {
                                                        forId: generateId("quantity", mainIndex, "cross-connect", subIndex, undefined),
                                                        status: Belt_Option.map(subValidationResults, (function (x) {
                                                                return x.quantity;
                                                              })),
                                                        children: "Quantity"
                                                      }),
                                                  JsxRuntime.jsx(TextField.make, {
                                                        id: generateId("quantity", mainIndex, "cross-connect", subIndex, undefined),
                                                        value: value !== undefined ? String(value) : "",
                                                        status: Belt_Option.map(subValidationResults, (function (x) {
                                                                if (x.quantity.TAG === "Ok") {
                                                                  return "Valid";
                                                                } else {
                                                                  return "Error";
                                                                }
                                                              })),
                                                        className: css.crossConnectQuantity,
                                                        onChange: (function ($$event) {
                                                            var value = $$event.target.value;
                                                            updateCrossConnects(configuration, {
                                                                  quantity: value,
                                                                  interconnect: crossConnect.interconnect,
                                                                  provider: crossConnect.provider,
                                                                  specialNotesAndRequirements: crossConnect.specialNotesAndRequirements
                                                                }, mainIndex, subIndex);
                                                          })
                                                      })
                                                ],
                                                className: css.field
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                        field: "Cross Connects Interconnect",
                                                        children: JsxRuntime.jsx(Label.make, {
                                                              forId: generateId("interconnect", mainIndex, "cross-connect", subIndex, undefined),
                                                              children: "Access Type"
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs(Select.make, {
                                                        id: generateId("interconnect", mainIndex, "cross-connect", subIndex, undefined),
                                                        value: Belt_Option.getWithDefault(crossConnect.interconnect, ""),
                                                        wrapperClassName: css.crossConnectInterconnect,
                                                        iconSize: "XXS",
                                                        iconColor: "GrayText",
                                                        onChange: (function ($$event) {
                                                            var value = $$event.target.value;
                                                            updateCrossConnects(configuration, {
                                                                  quantity: crossConnect.quantity,
                                                                  interconnect: value,
                                                                  provider: crossConnect.provider,
                                                                  specialNotesAndRequirements: crossConnect.specialNotesAndRequirements
                                                                }, mainIndex, subIndex);
                                                          }),
                                                        children: [
                                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                                value: "",
                                                                children: "Select"
                                                              }),
                                                          Belt_Array.map(options.interconnect, (function (value) {
                                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                                              value: value,
                                                                              children: value
                                                                            }, value);
                                                                }))
                                                        ]
                                                      })
                                                ],
                                                className: css.field
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                        field: "Cross Connects Preferred Provider",
                                                        children: JsxRuntime.jsx(Label.make, {
                                                              forId: generateId("provider", mainIndex, "cross-connect", subIndex, undefined),
                                                              children: "Preferred Provider"
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs(Select.make, {
                                                        id: generateId("provider", mainIndex, "cross-connect", subIndex, undefined),
                                                        value: Belt_Option.getWithDefault(crossConnect.provider, ""),
                                                        wrapperClassName: css.crossConnectPreferredProvider,
                                                        iconSize: "XXS",
                                                        iconColor: "GrayText",
                                                        onChange: (function ($$event) {
                                                            var value = $$event.target.value;
                                                            updateCrossConnects(configuration, {
                                                                  quantity: crossConnect.quantity,
                                                                  interconnect: crossConnect.interconnect,
                                                                  provider: value,
                                                                  specialNotesAndRequirements: crossConnect.specialNotesAndRequirements
                                                                }, mainIndex, subIndex);
                                                          }),
                                                        children: [
                                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                                value: "",
                                                                children: "Select"
                                                              }),
                                                          Belt_Array.map(options.crossConnectProviders, (function (value) {
                                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                                              value: value,
                                                                              children: value
                                                                            }, value);
                                                                }))
                                                        ]
                                                      })
                                                ],
                                                className: css.field
                                              })
                                        ],
                                        className: css.subSection
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx(Label.make, {
                                                      forId: generateId("special-notes-and-requirements", mainIndex, "cross-connect", subIndex, undefined),
                                                      children: "Special Notes and Requirements"
                                                    }),
                                                JsxRuntime.jsx(Textarea.make, {
                                                      id: generateId("special-notes-and-requirements", mainIndex, "cross-connect", subIndex, undefined),
                                                      value: Belt_Option.getWithDefault(crossConnect.specialNotesAndRequirements, ""),
                                                      className: css.internetConnectivitySpecialNotes,
                                                      onChange: (function ($$event) {
                                                          var value = $$event.target.value;
                                                          updateCrossConnects(configuration, {
                                                                quantity: crossConnect.quantity,
                                                                interconnect: crossConnect.interconnect,
                                                                provider: crossConnect.provider,
                                                                specialNotesAndRequirements: value
                                                              }, mainIndex, subIndex);
                                                        })
                                                    })
                                              ],
                                              className: Cx.cx([
                                                    css.field,
                                                    css.fullWidth
                                                  ])
                                            }),
                                        className: css.subSection
                                      })
                                ],
                                className: css.repeatedSection
                              }, String(subIndex));
                  })),
            JsxRuntime.jsx(Button.make, {
                  size: "MD",
                  color: "Gray",
                  buttonClassName: css.addRepeatedSection,
                  onClick: (function (param) {
                      updateConfiguration({
                            key: configuration.key,
                            startDate: configuration.startDate,
                            contractLength: configuration.contractLength,
                            rackTypesAndPowerDensities: configuration.rackTypesAndPowerDensities,
                            internetConnectivities: configuration.internetConnectivities,
                            crossConnects: Belt_Array.concat(configuration.crossConnects, [CreateColocationRfpProjectConfiguration_Data_CrossConnect.Input.empty]),
                            cloudDirectConnects: configuration.cloudDirectConnects,
                            certificationRequirements: configuration.certificationRequirements,
                            datacenterFeatures: configuration.datacenterFeatures,
                            additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
                          }, mainIndex);
                    }),
                  children: "+ Add Another"
                })
          ]
        }) : null;
  return JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
              title: "Cross Connects",
              visibility: sectionVisibility,
              toggleVisibility: (function () {
                  toggleVisibility(mainIndex, "CrossConnect");
                }),
              tooltip: (function (children) {
                  return JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                              field: "Cross Connects",
                              iconContainerClass: css.tooltipToRight,
                              children: children
                            });
                }),
              children: tmp
            });
}

var make = CreateColocationRfpProjectConfiguration_CrossConnect;

export {
  css ,
  make ,
}
/* css Not a pure module */
