// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as TextField from "../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconDelete from "../../../../styleguide/icons/IconDelete.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as UserColocationRfpProjectWizardTooltip from "../_components/UserColocationRfpProjectWizardTooltip/UserColocationRfpProjectWizardTooltip.res.js";
import * as CreateColocationRfpProjectConfigurationScss from "./CreateColocationRfpProjectConfiguration.scss";
import * as CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity from "./CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.res.js";

var css = CreateColocationRfpProjectConfigurationScss;

function CreateColocationRfpProjectConfiguration_RackTypesAndPowerDensities(props) {
  var options = props.options;
  var updateConfiguration = props.updateConfiguration;
  var configuration = props.configuration;
  var mainValidationResults = props.mainValidationResults;
  var mainIndex = props.mainIndex;
  var generateId = props.generateId;
  var updateRackTypes = function (configuration, rackType, mainIndex, subIndex) {
    var newRackTypes = Belt_Array.mapWithIndex(configuration.rackTypesAndPowerDensities, (function (idx, oldRackType) {
            if (idx === subIndex) {
              return rackType;
            } else {
              return oldRackType;
            }
          }));
    updateConfiguration({
          key: configuration.key,
          startDate: configuration.startDate,
          contractLength: configuration.contractLength,
          rackTypesAndPowerDensities: newRackTypes,
          internetConnectivities: configuration.internetConnectivities,
          crossConnects: configuration.crossConnects,
          cloudDirectConnects: configuration.cloudDirectConnects,
          certificationRequirements: configuration.certificationRequirements,
          datacenterFeatures: configuration.datacenterFeatures,
          additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
        }, mainIndex);
  };
  return JsxRuntime.jsxs(ProjectWizardPanel.Section.make, {
              title: "Space and Power Requirements",
              children: [
                Belt_Array.mapWithIndex(configuration.rackTypesAndPowerDensities, (function (subIndex, rackType) {
                        var subValidationResults = mainValidationResults !== undefined ? Belt_Array.get(mainValidationResults.rackTypesAndPowerDensities, subIndex) : undefined;
                        var value = rackType.quantity;
                        var value$1 = rackType.powerValue;
                        return JsxRuntime.jsxs("div", {
                                    children: [
                                      subIndex > 0 ? JsxRuntime.jsx(Control.make, {
                                              className: css.deleteSubSection,
                                              onClick: (function (param) {
                                                  var rackTypesAndPowerDensities = Belt_Array.keepWithIndex(configuration.rackTypesAndPowerDensities, (function (_value, index) {
                                                          return index !== subIndex;
                                                        }));
                                                  updateConfiguration({
                                                        key: configuration.key,
                                                        startDate: configuration.startDate,
                                                        contractLength: configuration.contractLength,
                                                        rackTypesAndPowerDensities: rackTypesAndPowerDensities,
                                                        internetConnectivities: configuration.internetConnectivities,
                                                        crossConnects: configuration.crossConnects,
                                                        cloudDirectConnects: configuration.cloudDirectConnects,
                                                        certificationRequirements: configuration.certificationRequirements,
                                                        datacenterFeatures: configuration.datacenterFeatures,
                                                        additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
                                                      }, mainIndex);
                                                }),
                                              children: JsxRuntime.jsx(IconDelete.make, {
                                                    size: "SM",
                                                    color: "LightGray"
                                                  })
                                            }) : null,
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(Label.make, {
                                                            forId: generateId("quantity", mainIndex, "rack", subIndex, undefined),
                                                            status: Belt_Option.map(subValidationResults, (function (x) {
                                                                    return x.quantity;
                                                                  })),
                                                            className: css.required,
                                                            children: "Quantity"
                                                          }),
                                                      JsxRuntime.jsx(TextField.make, {
                                                            id: generateId("quantity", mainIndex, "rack", subIndex, undefined),
                                                            value: value !== undefined ? String(value) : "",
                                                            status: Belt_Option.map(subValidationResults, (function (x) {
                                                                    if (x.quantity.TAG === "Ok") {
                                                                      return "Valid";
                                                                    } else {
                                                                      return "Error";
                                                                    }
                                                                  })),
                                                            className: css.rackTypeQuantity,
                                                            onChange: (function ($$event) {
                                                                var value = $$event.target.value;
                                                                updateRackTypes(configuration, {
                                                                      quantity: value,
                                                                      colocationSpace: rackType.colocationSpace,
                                                                      powerCircuit: rackType.powerCircuit,
                                                                      powerValue: rackType.powerValue,
                                                                      powerUnits: rackType.powerUnits,
                                                                      additionalOptions: rackType.additionalOptions
                                                                    }, mainIndex, subIndex);
                                                              })
                                                          })
                                                    ],
                                                    className: css.field
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                            field: "Colocation space",
                                                            children: JsxRuntime.jsx(Label.make, {
                                                                  forId: generateId("colocation-space", mainIndex, "rack", subIndex, undefined),
                                                                  status: Belt_Option.map(subValidationResults, (function (x) {
                                                                          return x.colocationSpace;
                                                                        })),
                                                                  className: css.required,
                                                                  children: "Colocation Space"
                                                                })
                                                          }),
                                                      JsxRuntime.jsxs(Select.make, {
                                                            id: generateId("colocation-space", mainIndex, "rack", subIndex, undefined),
                                                            value: Belt_Option.getWithDefault(rackType.colocationSpace, ""),
                                                            status: Belt_Option.map(subValidationResults, (function (x) {
                                                                    if (x.colocationSpace.TAG === "Ok") {
                                                                      return "Valid";
                                                                    } else {
                                                                      return "Error";
                                                                    }
                                                                  })),
                                                            wrapperClassName: css.rackTypeColocationSpace,
                                                            iconSize: "XXS",
                                                            iconColor: "GrayText",
                                                            onChange: (function ($$event) {
                                                                var value = $$event.target.value;
                                                                updateRackTypes(configuration, {
                                                                      quantity: rackType.quantity,
                                                                      colocationSpace: value,
                                                                      powerCircuit: rackType.powerCircuit,
                                                                      powerValue: rackType.powerValue,
                                                                      powerUnits: rackType.powerUnits,
                                                                      additionalOptions: rackType.additionalOptions
                                                                    }, mainIndex, subIndex);
                                                              }),
                                                            children: [
                                                              JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                    children: "Select"
                                                                  }),
                                                              Belt_Array.map(options.colocationSpace, (function (value) {
                                                                      return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                  value: value,
                                                                                  children: value
                                                                                }, value);
                                                                    }))
                                                            ]
                                                          })
                                                    ],
                                                    className: css.field
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                            field: "Power circuit",
                                                            children: JsxRuntime.jsx(Label.make, {
                                                                  forId: generateId("power-circuit", mainIndex, "rack", subIndex, undefined),
                                                                  children: "Circuit Type"
                                                                })
                                                          }),
                                                      JsxRuntime.jsxs(Select.make, {
                                                            id: generateId("power-circuit", mainIndex, "rack", subIndex, undefined),
                                                            value: Belt_Option.getWithDefault(rackType.powerCircuit, ""),
                                                            wrapperClassName: css.powerDensityPowerCircuit,
                                                            iconSize: "XXS",
                                                            iconColor: "GrayText",
                                                            onChange: (function ($$event) {
                                                                var value = $$event.target.value;
                                                                updateRackTypes(configuration, {
                                                                      quantity: rackType.quantity,
                                                                      colocationSpace: rackType.colocationSpace,
                                                                      powerCircuit: value,
                                                                      powerValue: rackType.powerValue,
                                                                      powerUnits: rackType.powerUnits,
                                                                      additionalOptions: rackType.additionalOptions
                                                                    }, mainIndex, subIndex);
                                                              }),
                                                            children: [
                                                              JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                    selectable: true,
                                                                    children: "Select"
                                                                  }),
                                                              Belt_Array.map(options.powerCircuit, (function (value) {
                                                                      return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                  value: value,
                                                                                  children: value
                                                                                }, value);
                                                                    }))
                                                            ]
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.field,
                                                          css.noRightMargin
                                                        ])
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: "or",
                                                    className: css.powerDensityOrLabel
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                            field: "Total Power",
                                                            children: JsxRuntime.jsx(Label.make, {
                                                                  forId: generateId("power-value", mainIndex, "rack", subIndex, undefined),
                                                                  status: Belt_Option.map(subValidationResults, (function (x) {
                                                                          return x.powerValue;
                                                                        })),
                                                                  children: "Total Power"
                                                                })
                                                          }),
                                                      JsxRuntime.jsxs("div", {
                                                            children: [
                                                              JsxRuntime.jsx(TextField.make, {
                                                                    id: generateId("power-value", mainIndex, "rack", subIndex, undefined),
                                                                    value: value$1 !== undefined ? String(value$1) : "",
                                                                    status: Belt_Option.map(subValidationResults, (function (x) {
                                                                            if (x.powerValue.TAG === "Ok") {
                                                                              return "Valid";
                                                                            } else {
                                                                              return "Error";
                                                                            }
                                                                          })),
                                                                    className: css.powerDensityPowerValue,
                                                                    onChange: (function ($$event) {
                                                                        var value = $$event.target.value;
                                                                        var tmp = value === "" ? undefined : value;
                                                                        updateRackTypes(configuration, {
                                                                              quantity: rackType.quantity,
                                                                              colocationSpace: rackType.colocationSpace,
                                                                              powerCircuit: rackType.powerCircuit,
                                                                              powerValue: tmp,
                                                                              powerUnits: rackType.powerUnits,
                                                                              additionalOptions: rackType.additionalOptions
                                                                            }, mainIndex, subIndex);
                                                                      })
                                                                  }),
                                                              JsxRuntime.jsxs(Select.make, {
                                                                    id: generateId("power-units", mainIndex, "rack", subIndex, undefined),
                                                                    value: Belt_Option.getWithDefault(rackType.powerUnits, ""),
                                                                    status: Belt_Option.map(subValidationResults, (function (x) {
                                                                            if (x.powerUnits.TAG === "Ok") {
                                                                              return "Valid";
                                                                            } else {
                                                                              return "Error";
                                                                            }
                                                                          })),
                                                                    className: css.powerDensityPowerUnitsSelect,
                                                                    wrapperClassName: css.powerDensityPowerUnits,
                                                                    iconSize: "XXS",
                                                                    iconColor: "GrayText",
                                                                    onChange: (function ($$event) {
                                                                        var value = $$event.target.value;
                                                                        updateRackTypes(configuration, {
                                                                              quantity: rackType.quantity,
                                                                              colocationSpace: rackType.colocationSpace,
                                                                              powerCircuit: rackType.powerCircuit,
                                                                              powerValue: rackType.powerValue,
                                                                              powerUnits: value,
                                                                              additionalOptions: rackType.additionalOptions
                                                                            }, mainIndex, subIndex);
                                                                      }),
                                                                    children: [
                                                                      JsxRuntime.jsx(Select.$$Option.make, {
                                                                            value: "",
                                                                            children: ""
                                                                          }),
                                                                      Belt_Array.map(options.powerUnits, (function (value) {
                                                                              return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                          value: value,
                                                                                          children: value
                                                                                        }, value);
                                                                            }))
                                                                    ]
                                                                  })
                                                            ],
                                                            className: css.powerDensityPowerContainer
                                                          })
                                                    ],
                                                    className: css.field
                                                  })
                                            ],
                                            className: css.subSection
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: Belt_Array.map(options.additionalSpaceAndPower, (function (spaceAndPower) {
                                                    return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                id: generateId("additional-option-" + spaceAndPower, mainIndex, "rack", subIndex, undefined),
                                                                size: "SM",
                                                                checked: (function (__x) {
                                                                      return Js_array.includes(spaceAndPower, __x);
                                                                    })(rackType.additionalOptions),
                                                                containerClassName: css.option,
                                                                onChange: (function ($$event) {
                                                                    var checked = $$event.target.checked;
                                                                    var additionalOptions = checked ? Belt_Array.concat(rackType.additionalOptions, [spaceAndPower]) : Belt_Array.keep(rackType.additionalOptions, (function (value) {
                                                                              return value !== spaceAndPower;
                                                                            }));
                                                                    updateRackTypes(configuration, {
                                                                          quantity: rackType.quantity,
                                                                          colocationSpace: rackType.colocationSpace,
                                                                          powerCircuit: rackType.powerCircuit,
                                                                          powerValue: rackType.powerValue,
                                                                          powerUnits: rackType.powerUnits,
                                                                          additionalOptions: additionalOptions
                                                                        }, mainIndex, subIndex);
                                                                  }),
                                                                children: JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                                      field: spaceAndPower,
                                                                      children: spaceAndPower
                                                                    })
                                                              }, spaceAndPower);
                                                  })),
                                            className: css.subSection
                                          })
                                    ],
                                    className: css.repeatedSection
                                  }, String(subIndex));
                      })),
                JsxRuntime.jsx(Button.make, {
                      size: "SM",
                      color: "Gray",
                      buttonClassName: css.addRepeatedSection,
                      onClick: (function (param) {
                          updateConfiguration({
                                key: configuration.key,
                                startDate: configuration.startDate,
                                contractLength: configuration.contractLength,
                                rackTypesAndPowerDensities: Belt_Array.concat(configuration.rackTypesAndPowerDensities, [CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.Input.empty]),
                                internetConnectivities: configuration.internetConnectivities,
                                crossConnects: configuration.crossConnects,
                                cloudDirectConnects: configuration.cloudDirectConnects,
                                certificationRequirements: configuration.certificationRequirements,
                                datacenterFeatures: configuration.datacenterFeatures,
                                additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
                              }, mainIndex);
                        }),
                      children: "+ Add Another"
                    })
              ]
            });
}

var make = CreateColocationRfpProjectConfiguration_RackTypesAndPowerDensities;

export {
  css ,
  make ,
}
/* css Not a pure module */
