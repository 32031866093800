// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Textarea from "../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconDelete from "../../../../styleguide/icons/IconDelete.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as UserColocationRfpProjectWizardTooltip from "../_components/UserColocationRfpProjectWizardTooltip/UserColocationRfpProjectWizardTooltip.res.js";
import * as CreateColocationRfpProjectConfigurationScss from "./CreateColocationRfpProjectConfiguration.scss";
import * as CreateColocationRfpProjectConfiguration_Data_CloudDirectConnect from "./CreateColocationRfpProjectConfiguration_Data_CloudDirectConnect.res.js";

var css = CreateColocationRfpProjectConfigurationScss;

function CreateColocationRfpProjectConfiguration_CloudDirectConnect(props) {
  var toggleVisibility = props.toggleVisibility;
  var sectionVisibility = props.sectionVisibility;
  var options = props.options;
  var updateConfiguration = props.updateConfiguration;
  var configuration = props.configuration;
  var mainIndex = props.mainIndex;
  var generateId = props.generateId;
  var updateCloudDirectConnects = function (configuration, cloudDirectConnect, mainIndex, subIndex) {
    var cloudDirectConnects = Belt_Array.mapWithIndex(configuration.cloudDirectConnects, (function (idx, oldCloudDirectConnect) {
            if (idx === subIndex) {
              return cloudDirectConnect;
            } else {
              return oldCloudDirectConnect;
            }
          }));
    updateConfiguration({
          key: configuration.key,
          startDate: configuration.startDate,
          contractLength: configuration.contractLength,
          rackTypesAndPowerDensities: configuration.rackTypesAndPowerDensities,
          internetConnectivities: configuration.internetConnectivities,
          crossConnects: configuration.crossConnects,
          cloudDirectConnects: cloudDirectConnects,
          certificationRequirements: configuration.certificationRequirements,
          datacenterFeatures: configuration.datacenterFeatures,
          additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
        }, mainIndex);
  };
  var tmp;
  tmp = sectionVisibility === "Shown" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            Belt_Array.mapWithIndex(configuration.cloudDirectConnects, (function (subIndex, cloudDirectConnect) {
                    return JsxRuntime.jsxs("div", {
                                children: [
                                  subIndex > 0 ? JsxRuntime.jsx(Control.make, {
                                          className: css.deleteSubSection,
                                          onClick: (function (param) {
                                              var cloudDirectConnects = Belt_Array.keepWithIndex(configuration.cloudDirectConnects, (function (_value, index) {
                                                      return index !== subIndex;
                                                    }));
                                              updateConfiguration({
                                                    key: configuration.key,
                                                    startDate: configuration.startDate,
                                                    contractLength: configuration.contractLength,
                                                    rackTypesAndPowerDensities: configuration.rackTypesAndPowerDensities,
                                                    internetConnectivities: configuration.internetConnectivities,
                                                    crossConnects: configuration.crossConnects,
                                                    cloudDirectConnects: cloudDirectConnects,
                                                    certificationRequirements: configuration.certificationRequirements,
                                                    datacenterFeatures: configuration.datacenterFeatures,
                                                    additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
                                                  }, mainIndex);
                                            }),
                                          children: JsxRuntime.jsx(IconDelete.make, {
                                                size: "SM",
                                                color: "LightGray"
                                              })
                                        }) : null,
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                        field: "Cloud Direct Connects Interconnect",
                                                        children: JsxRuntime.jsx(Label.make, {
                                                              forId: generateId("interconnect", mainIndex, "cloud-direct-connect", subIndex, undefined),
                                                              children: "Access Type"
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs(Select.make, {
                                                        id: generateId("interconnect", mainIndex, "cloud-direct-connect", subIndex, undefined),
                                                        value: Belt_Option.getWithDefault(cloudDirectConnect.interconnect, ""),
                                                        wrapperClassName: css.cloudDirectInterconnect,
                                                        iconSize: "XXS",
                                                        iconColor: "GrayText",
                                                        onChange: (function ($$event) {
                                                            var value = $$event.target.value;
                                                            updateCloudDirectConnects(configuration, {
                                                                  interconnect: value,
                                                                  bandwidth: cloudDirectConnect.bandwidth,
                                                                  cloudPlatform: cloudDirectConnect.cloudPlatform,
                                                                  additionalOptions: cloudDirectConnect.additionalOptions,
                                                                  specialNotesAndRequirements: cloudDirectConnect.specialNotesAndRequirements
                                                                }, mainIndex, subIndex);
                                                          }),
                                                        children: [
                                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                                value: "",
                                                                children: "Select"
                                                              }),
                                                          Belt_Array.map(options.interconnect, (function (value) {
                                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                                              value: value,
                                                                              children: value
                                                                            }, value);
                                                                }))
                                                        ]
                                                      })
                                                ],
                                                className: css.field
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                        field: "Cloud Direct Connects Bandwidth",
                                                        children: JsxRuntime.jsx(Label.make, {
                                                              forId: generateId("bandwidth", mainIndex, "cloud-direct-connect", subIndex, undefined),
                                                              children: "Bandwidth"
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs(Select.make, {
                                                        id: generateId("bandwidth", mainIndex, "cloud-direct-connect", subIndex, undefined),
                                                        value: Belt_Option.getWithDefault(cloudDirectConnect.bandwidth, ""),
                                                        wrapperClassName: css.cloudDirectBandwidth,
                                                        iconSize: "XXS",
                                                        iconColor: "GrayText",
                                                        onChange: (function ($$event) {
                                                            var value = $$event.target.value;
                                                            updateCloudDirectConnects(configuration, {
                                                                  interconnect: cloudDirectConnect.interconnect,
                                                                  bandwidth: value,
                                                                  cloudPlatform: cloudDirectConnect.cloudPlatform,
                                                                  additionalOptions: cloudDirectConnect.additionalOptions,
                                                                  specialNotesAndRequirements: cloudDirectConnect.specialNotesAndRequirements
                                                                }, mainIndex, subIndex);
                                                          }),
                                                        children: [
                                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                                value: "",
                                                                children: "Select"
                                                              }),
                                                          Belt_Array.map(options.bandwidths, (function (value) {
                                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                                              value: value,
                                                                              children: value
                                                                            }, value);
                                                                }))
                                                        ]
                                                      })
                                                ],
                                                className: css.field
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                        field: "Cloud Platform",
                                                        children: JsxRuntime.jsx(Label.make, {
                                                              forId: generateId("cloud-platform", mainIndex, "cloud-direct-connect", subIndex, undefined),
                                                              children: "Cloud Platform"
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs(Select.make, {
                                                        id: generateId("cloud-platform", mainIndex, "cloud-direct-connect", subIndex, undefined),
                                                        value: Belt_Option.getWithDefault(cloudDirectConnect.cloudPlatform, ""),
                                                        wrapperClassName: css.cloudDirectCloudPlatform,
                                                        iconSize: "XXS",
                                                        iconColor: "GrayText",
                                                        onChange: (function ($$event) {
                                                            var value = $$event.target.value;
                                                            updateCloudDirectConnects(configuration, {
                                                                  interconnect: cloudDirectConnect.interconnect,
                                                                  bandwidth: cloudDirectConnect.bandwidth,
                                                                  cloudPlatform: value,
                                                                  additionalOptions: cloudDirectConnect.additionalOptions,
                                                                  specialNotesAndRequirements: cloudDirectConnect.specialNotesAndRequirements
                                                                }, mainIndex, subIndex);
                                                          }),
                                                        children: [
                                                          JsxRuntime.jsx(Select.$$Option.make, {
                                                                value: "",
                                                                children: "Select"
                                                              }),
                                                          Belt_Array.map(options.cloudPlatforms, (function (value) {
                                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                                              value: value,
                                                                              children: value
                                                                            }, value);
                                                                }))
                                                        ]
                                                      })
                                                ],
                                                className: css.field
                                              })
                                        ],
                                        className: css.subSection
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: Belt_Array.map(options.additionalCloudDirectConnects, (function (additionalOption) {
                                                return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                            id: generateId("additional-option-" + additionalOption, mainIndex, "cloud-direct-connect", subIndex, undefined),
                                                            size: "SM",
                                                            checked: (function (__x) {
                                                                  return Js_array.includes(additionalOption, __x);
                                                                })(cloudDirectConnect.additionalOptions),
                                                            containerClassName: css.option,
                                                            onChange: (function ($$event) {
                                                                var checked = $$event.target.checked;
                                                                var additionalOptions = checked ? Belt_Array.concat(cloudDirectConnect.additionalOptions, [additionalOption]) : Belt_Array.keep(cloudDirectConnect.additionalOptions, (function (value) {
                                                                          return value !== additionalOption;
                                                                        }));
                                                                updateCloudDirectConnects(configuration, {
                                                                      interconnect: cloudDirectConnect.interconnect,
                                                                      bandwidth: cloudDirectConnect.bandwidth,
                                                                      cloudPlatform: cloudDirectConnect.cloudPlatform,
                                                                      additionalOptions: additionalOptions,
                                                                      specialNotesAndRequirements: cloudDirectConnect.specialNotesAndRequirements
                                                                    }, mainIndex, subIndex);
                                                              }),
                                                            children: JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                                                                  field: "Cloud Direct Connects " + additionalOption,
                                                                  children: additionalOption
                                                                })
                                                          }, additionalOption);
                                              })),
                                        className: css.subSection
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx(Label.make, {
                                                      forId: generateId("special-notes-and-requirements", mainIndex, "cloud-direct-connect", subIndex, undefined),
                                                      children: "Special Notes and Requirements"
                                                    }),
                                                JsxRuntime.jsx(Textarea.make, {
                                                      id: generateId("special-notes-and-requirements", mainIndex, "cloud-direct-connect", subIndex, undefined),
                                                      value: Belt_Option.getWithDefault(cloudDirectConnect.specialNotesAndRequirements, ""),
                                                      onChange: (function ($$event) {
                                                          var value = $$event.target.value;
                                                          updateCloudDirectConnects(configuration, {
                                                                interconnect: cloudDirectConnect.interconnect,
                                                                bandwidth: cloudDirectConnect.bandwidth,
                                                                cloudPlatform: cloudDirectConnect.cloudPlatform,
                                                                additionalOptions: cloudDirectConnect.additionalOptions,
                                                                specialNotesAndRequirements: value
                                                              }, mainIndex, subIndex);
                                                        })
                                                    })
                                              ],
                                              className: Cx.cx([
                                                    css.field,
                                                    css.fullWidth
                                                  ])
                                            }),
                                        className: css.subSection
                                      })
                                ],
                                className: css.repeatedSection
                              }, String(subIndex));
                  })),
            JsxRuntime.jsx(Button.make, {
                  size: "MD",
                  color: "Gray",
                  buttonClassName: css.addRepeatedSection,
                  onClick: (function (param) {
                      updateConfiguration({
                            key: configuration.key,
                            startDate: configuration.startDate,
                            contractLength: configuration.contractLength,
                            rackTypesAndPowerDensities: configuration.rackTypesAndPowerDensities,
                            internetConnectivities: configuration.internetConnectivities,
                            crossConnects: configuration.crossConnects,
                            cloudDirectConnects: Belt_Array.concat(configuration.cloudDirectConnects, [CreateColocationRfpProjectConfiguration_Data_CloudDirectConnect.Input.empty]),
                            certificationRequirements: configuration.certificationRequirements,
                            datacenterFeatures: configuration.datacenterFeatures,
                            additionalNotesOrRequirements: configuration.additionalNotesOrRequirements
                          }, mainIndex);
                    }),
                  children: "+ Add Another"
                })
          ]
        }) : null;
  return JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
              title: "Cloud Direct Connects",
              visibility: sectionVisibility,
              toggleVisibility: (function () {
                  toggleVisibility(mainIndex, "CloudDirectConnect");
                }),
              tooltip: (function (children) {
                  return JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                              field: "Cloud Direct Connects",
                              iconContainerClass: css.tooltipToRight,
                              children: children
                            });
                }),
              children: tmp
            });
}

var make = CreateColocationRfpProjectConfiguration_CloudDirectConnect;

export {
  css ,
  make ,
}
/* css Not a pure module */
